import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

export class UnauthorizedInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private readonly LOLACL_STORAGE_TENANT_ID_KEY = 'tenantId';

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem(this.LOLACL_STORAGE_TENANT_ID_KEY);
          console.error(error);
          alert('認証エラーが発生しました。再度ログインしてください。');
          this.router.navigateByUrl('/user/logout');

          return EMPTY;
        }

        return throwError(() => error);
      }),
    );
  }
}
