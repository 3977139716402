import { ServerType } from './environment.model';

export const environment = {
  production: false,
  storagePath: 'https://static.dev.truss.me/',
  //storagePath: '/storage/',
  gaTrackingId: 'UA-58775646-9',
  serverType: ServerType.Dev,
  clarity: "9uqag6gdwb",
  gtmId: 'GTM-NWP44XDR',
  stripeKey: 'pk_test_51OAQe2CVqa4Ss53E2qikomBbFotl60OKaR0y9vCptO5uqNTTJV1qDK4a0osWe0BCHePgTldRrtvyVfwwReoYgMYM00Fh4WQ0YG',
  stripeTableId: 'prctbl_1Pfay8CVqa4Ss53EQQOhmDE2',
  launchDarklyKey: "665ca6e159e1d110020d35a2",
  agGridEnterpriseKey: "Using_this_{AG_Grid}_Enterprise_key_{AG-072767}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{truss_inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{truss}_only_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{truss}_need_to_be_licensed___{truss}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_December_2025}____[v3]_[01]_MTc2NDcyMDAwMDAwMA==855e287e3792aec40012658eeafb1a77",
};
