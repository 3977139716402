import { CdkDropList } from '@angular/cdk/drag-drop';
import { inject, Injectable } from '@angular/core';
import { ExteriorCategoryApiService } from '@shared/api/exterior-category-api.service';
import { DecidedProductInfo } from '@shared/models/response/decided-product-info';
import { InteriorSet } from '@shared/models/response/interior-set';
import { CandidateProductFacade } from 'app/store/candidate-product/facades/candidate-product.facade';
import { GetFloorGroupViewModelQuery } from 'app/v2/general/application/query/services/floor-group/get-floor-group-view-model-query';
import { FinishScheduleStore } from 'app/v2/general/application/store/finish-schedule.store';
import { ProjectStore } from 'app/v2/general/application/store/project.store';
import { FloorGroupViewModel } from 'app/v2/general/application/view-model/floor-group-view-model';
import { ExteriorCategory } from 'app/v2/general/features/project/_type/exterior-category';
import { ExteriorSet } from 'app/v2/general/features/project/_type/exterior-category-set';
import { EditPermissionStore } from 'app/v2/general/features/project/stores/edit-permission-store';
import { SampleRequestPermissionStore } from 'app/v2/general/features/project/stores/sample-request-permission.store';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { of, zip } from 'rxjs';
import { switchMap, tap, catchError, map } from 'rxjs/operators';

import { ProjectSampleRequestCountStore } from '../../v2/general/application/store/project-sample-request-count.store';
import { ApiService } from '../api/api.service';
import {
  InteriorPicklistParams,
  ExteriorPicklistParams,
  OutdoorPicklistParams,
} from '../api/project.service';
import { SearchSimilarProductDialogService } from '../dialog/search-similar-product-dialog/search-similar-product-dialog.service';
import { FavButtonChangeStartEvent } from '../models/response/favlist-event';
import { ReloadFavTriggerEvent } from '../models/response/favlist-event';
import { FavListCopyStartEvent } from '../models/response/favlist-event';
import { InteriorCategory } from '../models/response/interior-category';
import { InteriorSetParts } from '../models/response/interior-set-parts';
import { Picklist } from '../models/response/picklist';
import { ProductCache } from '../models/response/product-cache';
import { Project } from '../models/response/project';
import { RoomBlockParts } from '../models/response/room-block';
import { RoomGroup } from '../models/response/room-group';
import {
  ProjectConditions,
  SearchPicklistCategories,
} from '../models/response/search-picklist-categories';
import {
  getPicklistTypeLabel,
  PicklistType,
} from '../models/response/sub/picklist-type';
import { ProjectType } from '../models/response/sub/project-type';

import { SearchService } from './search.service';
import { UserService } from './user.service';

interface BrowseSetParts {
  name?: string;
  types?: [BrowseSetType];
}

interface BrowseSetType {
  id?: number;
  type?: string;
  parts?: InteriorSetParts;
}

interface BrowsePicklist {
  type?: string;
  picklists?: {
    picklist?: Picklist;
    order?: number;
  }[];
  parts?: InteriorSetParts | undefined;
}

interface BrowseInfo {
  name?: string;
  types?: BrowsePicklist[][];
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectSampleRequestCountStore = inject(
    ProjectSampleRequestCountStore,
  );
  private projectStore = inject(ProjectStore);

  //projectPageServiceの2重処理監視用
  //projectPageService廃止後は不要
  needCheckUpdate = true;

  finishSchedule?: Project;
  picklistHolders: CdkDropList[];
  dragTarget?: InteriorSetParts | ExteriorSet;
  dragPicklist?: Picklist;
  dragIndex?: number;

  onChangeDecidedProductInfo$ = new Subject<DecidedProductInfo | undefined>();
  onChangePicklist$ = new Subject<void>();
  onUpdateProject$ = new Subject<void>();
  onLoadedProjectCondtion$ = new Subject<void>();
  onLoadedFinishSchedule$ = new BehaviorSubject<boolean>(false);
  reloadCandidateTrigger$ = new Subject();
  disableScrollTrigger$ = new Subject<boolean>();
  projectConditions: ProjectConditions;
  pageName = '';
  isLoadingFinishSchedule = false;
  onRefreshPicklistNodes = new Subject<void>();
  isRevit$ = new BehaviorSubject<boolean | undefined>(undefined);
  onGenerateMessage$ = new Subject<{ message: string; keep?: number }>();
  highlightPicklist$ = new BehaviorSubject<Picklist | undefined>(undefined);

  onChangeProjectId$ = new Subject<{
    projectId: number;
    onEnd: Subject<undefined>;
    isReload?: boolean;
  }>();

  _favToggleEvent: Subject<
    FavButtonChangeStartEvent | FavListCopyStartEvent | ReloadFavTriggerEvent
  >;

  _updateProject = new Subject<undefined>();
  get updateProject$() {
    return this._updateProject.asObservable();
  }

  set updateProject(value: undefined) {
    this._updateProject.next(value);
  }

  get onRefreshPicklistNodes$() {
    return this.onRefreshPicklistNodes.asObservable();
  }

  _currentPicklist?: Picklist;
  get currentPicklist() {
    if (this._currentPicklist) {
      return this._currentPicklist;
    }

    if (this.finishSchedule && this._currentPicklistId) {
      this._currentPicklist = this.allPicklists.find((picklist) => {
        return picklist.id == this._currentPicklistId;
      });
    }

    return this._currentPicklist;
  }

  set currentPicklist(value: Picklist | undefined) {
    this._currentPicklist = value;
    if (!value) {
      this.currentPicklistId = undefined;
    }

    if (value && !this._currentPicklistId) {
      this._currentPicklistId = value.id;
    }

    if (value && (value.type !== this.picklistType || !this.picklistType)) {
      this.initPicklistType(value.type);
    }
  }

  _currentPicklistId?: number;
  get currentPicklistId() {
    if (this._currentPicklistId) {
      return this._currentPicklistId;
    } else {
      if (this._currentPicklist) {
        return this._currentPicklist.id;
      }
    }
    return undefined;
  }

  set currentPicklistId(value: number | undefined) {
    this._currentPicklistId = value;

    if (this.finishSchedule && !this._currentPicklist) {
      this._currentPicklist = this.allPicklists.find((picklist) => {
        return picklist.id == this._currentPicklistId;
      });
    }
  }

  get isSearchPageForEnableProjectUser() {
    return !!this.currentPicklistId && !!this.currentProjectId;
  }

  _currentProject: Project | undefined;
  get currentProject() {
    return this.user.projects.length
      ? this.user.projects.find((p) => {
          return p?.id == this.currentProjectId;
        })
      : undefined;
  }

  _currentProjectId: number | undefined;
  get currentProjectId() {
    return this._currentProjectId;
  }

  set currentProjectId(projectId: number | undefined) {
    this._currentProjectId = projectId;
  }

  get favToggleEvent() {
    return this._favToggleEvent;
  }

  set favToggleEvent(
    _favToggleEvent: Subject<
      FavButtonChangeStartEvent | FavListCopyStartEvent | ReloadFavTriggerEvent
    >,
  ) {
    this._favToggleEvent = _favToggleEvent;
  }

  get enable(): boolean {
    return !!this.user.enableProject;
  }

  get showMenu(): boolean {
    // return this.enable && this._showMenu;
    return this.enable;
  }
  set showMenu(value: boolean) {
    this._showMenu = value;
  }
  private _showMenu = true;

  get picklistType(): PicklistType {
    return (
      this._picklistType ||
      (this.currentPicklist ? this.currentPicklist.type : PicklistType.Other)
    );
  }
  _picklistType: PicklistType | undefined;

  initPicklistType(value: PicklistType | undefined): void {
    this._picklistType = value;
  }

  initFinishSchedule(): void {
    this.finishSchedule = undefined;
  }

  get pageTitle(): string {
    return getPicklistTypeLabel(this.picklistType);
  }

  get searchCategories(): SearchPicklistCategories | undefined {
    return this.search.searchCategories;
  }

  get currentCategories() {
    return this.searchCategories
      ? this.searchCategories[this.picklistType]
      : [];
  }

  get projectId(): number {
    return this.finishSchedule!.id;
  }

  get picklists(): Picklist[] {
    if (!this.finishSchedule) return [];

    return this.finishSchedule?.picklists || [];
  }

  get parentPicklists(): Picklist[] {
    if (!this.finishSchedule) return [];

    return this.finishSchedule?.parentPicklists || [];
  }

  get allPicklists(): Picklist[] {
    let _picklists: Picklist[] = [];

    _picklists = this.picklists.concat();
    _picklists = [..._picklists, ...this.parentPicklists.concat()];

    return _picklists;
  }

  get floorGroups() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.floor_groups!;
  }

  get interiroSets() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.interior_sets!;
  }

  get interiorCategories() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.interior_categories!;
  }

  get canApprove(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return true;
    const canApprove = this.finishSchedule!.can_approve;

    if (canApprove == null) {
      return false;
    }

    return canApprove;
  }

  set canApprove(canApprove: boolean | undefined) {
    this.finishSchedule!.can_approve = canApprove;
  }

  _updatedProductInfoAt = new Subject<Date | undefined>();

  get updatedProductInfoAt$() {
    return this._updatedProductInfoAt.asObservable();
  }

  set updatedProductInfoAt(updatedAt: Date | undefined) {
    this._updatedProductInfoAt.next(updatedAt);
  }

  get exteriorCategories() {
    if (!this.finishSchedule) return [];

    return this.finishSchedule!.exterior_categories!;
  }

  get canEdit(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return true;
    const canEdit = this.finishSchedule!.can_edit;

    if (canEdit == null) {
      return false;
    }

    return canEdit;
  }

  set canEdit(canEdit: boolean | undefined) {
    this.finishSchedule!.can_edit = canEdit;
    this.isBrowseMode = !canEdit!;
    //2023.06.08 材料詳細に権限状態を反映させるためストアに状態を保存
    this.editPermissionStore.updateCanEdit(canEdit);
  }

  get canRequest(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return false;
    const canRequest = this.finishSchedule!.can_request;

    if (canRequest == null) {
      return false;
    }

    return canRequest;
  }

  set canRequest(canRequest: boolean | undefined) {
    this.finishSchedule!.can_request = canRequest;
    //2023.06.08 材料詳細に権限状態を反映させるためストアに状態を保存
    this.editPermissionStore.updateCanRequest(canRequest);
  }

  /**
   * 2023.09.21 サンプル請求編集権限
   */
  get canRequestSample(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return false;
    const canRequestSample = this.finishSchedule!.can_request_sample;

    if (canRequestSample == null) {
      return false;
    }

    return canRequestSample;
  }

  /**
   * 2023.09.21 サンプル請求提案権限
   */
  get canSuggestSample(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return false;
    const canSuggestSample = this.finishSchedule!.can_suggest_sample;

    if (canSuggestSample == null) {
      return false;
    }

    return canSuggestSample;
  }

  get isOnlyRequest() {
    return !this.canEdit && !!this.canRequest;
  }

  get isTemplate(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return false;

    return this.finishSchedule!.project_type == ProjectType.Template;
  }

  get isSample(): boolean | undefined {
    if (!this.user.enableProject || !this.finishSchedule) return false;

    return this.finishSchedule!.project_type == ProjectType.Sample;
  }

  _isBrowseMode = false;

  get isBrowseMode(): boolean {
    return this._isBrowseMode;
  }

  set isBrowseMode(mode: boolean) {
    if (mode) this.setBrowseInfo();
    this._isBrowseMode = mode;
  }

  _isChangedBrowseMode = new Subject<boolean>();

  get onBrowseMode$(): Observable<boolean> {
    return this._isChangedBrowseMode.asObservable();
  }

  set isChangedBrowseMode(isChangedBrowseMode: boolean) {
    this._isChangedBrowseMode.next(isChangedBrowseMode);
  }

  _disableOperation = false;
  get disableOperation() {
    return this._disableOperation;
  }

  set disableOperation(status: boolean) {
    this._disableOperation = status;
  }

  constructor(
    private user: UserService,
    readonly search: SearchService,
    private api: ApiService,
    private candidateProductFacade: CandidateProductFacade,
    private exteriorCategoryApiService: ExteriorCategoryApiService,
    private finishScheduleStore: FinishScheduleStore,
    private editPermissionStore: EditPermissionStore,
    private getFloorGroupViewModelQuery: GetFloorGroupViewModelQuery,
    private sampleRequestPermissionStore: SampleRequestPermissionStore,
  ) {
    this.onUpdateProject$.subscribe(() => {
      this.api.category
        .projectConditions(this.projectId)
        .subscribe((condition) => {
          this.projectConditions = condition;
          this.onLoadedProjectCondtion$.next();
        });
    });

    this.onChangeProjectId$
      .pipe(
        tap(({ isReload }) => {
          this.isLoadingFinishSchedule = true;
          this.projectStore.patchState({
            isChanging: true,
          });
          this.finishScheduleStore.setState({
            finishSchedule: undefined,
            isLoading: true,
          });
          this.finishSchedule = isReload ? this.finishSchedule : undefined;
          if (!this.finishSchedule) {
            this.onLoadedFinishSchedule$.next(false);
          }
        }),
        switchMap(({ projectId, onEnd }) =>
          zip(
            this.api.project.finishSchedule(projectId).pipe(
              catchError((error) => {
                alert('プロジェクトの読み込み中にエラーが発生しました。');
                window.location.href = '/';
                return of(undefined);
              }),
            ),
            of(onEnd),
          ),
        ),
      )
      .subscribe(([finishSchedule, onEnd]) => {
        if (!finishSchedule) {
          this.onLoadedFinishSchedule$.next(false);
          return;
        }
        this.projectSampleRequestCountStore.updateProjectSampleProductCount(
          finishSchedule?.projectSampleProductCount ?? 0,
        );
        this._interiorStandardPicklistMap = new Map<string, number[]>();
        this._exteriorStandardPicklistMap = new Map<string, number[]>();
        this.finishSchedule = finishSchedule;

        this.candidateProductFacade.fetchAll(finishSchedule.picklists);

        const setFromId = this.interiroSets.reduce(
          (map, set) => ({ ...map, [set.id]: set }),
          {} as { [key: number]: InteriorSet },
        );
        for (const r of this.floorGroups.reduce(
          (rooms, floor) => [...rooms, ...floor.room_groups],
          [] as RoomGroup[],
        )) {
          r.interiorSet = setFromId[r.interior_set_id];
        }

        const picklistFromId = this.picklists.reduce(
          (map, picklist) => ({ ...map, [picklist.id]: picklist }),
          {} as { [key: number]: Picklist },
        );
        for (const parts of this.interiroSets.reduce(
          (parts, set) => [...parts, ...Object.values(set.parts)],
          [] as InteriorSetParts[],
        )) {
          parts.picklists = parts.picklistInfo
            .sort((a, b) => a.order - b.order)
            .map((i) => picklistFromId[i.id]);
        }

        for (const set of this.exteriorCategories.reduce(
          (sets, category) => [...sets, ...Object.values(category.sets)],
          [] as ExteriorSet[],
        )) {
          set.picklists = set.picklistInfo
            .sort((a, b) => a.order - b.order)
            .map((i) => picklistFromId[i.id]);
        }

        this.refleshPicklistGroups();

        const browseInfo = this.browseInfo;
        if (
          this.isBrowseMode &&
          browseInfo &&
          [...browseInfo.keys()][0] &&
          [...browseInfo.keys()][0]!.project_id !== this.finishSchedule.id &&
          this.finishSchedule.can_edit &&
          this.finishSchedule!.project_type == ProjectType.Project
        ) {
          // browseInfoに現在のプロジェクトと違うprojectIdが入っていたら閲覧モードをオフにする
          this.isBrowseMode = false;
        } else if (this.finishSchedule!.project_type !== ProjectType.Project) {
          this.isBrowseMode = true;
          this.canRequest = false;
        } else if (!this.finishSchedule.can_edit) {
          this.isBrowseMode = true;
        }

        //2023.06.08 材料詳細に権限状態を反映させるためストアに状態を保存
        this.editPermissionStore.setState({
          editPermissionState: {
            canEdit: this.canEdit,
            canRequest: this.canRequest,
            isOnlyRequest: this.isOnlyRequest,
            isBrowseMode: this.isBrowseMode,
          },
        });

        //サンプル請求権限状態をストアに保存
        this.sampleRequestPermissionStore.setState({
          sampleRequestPermissionState: {
            canRequest: this.canRequestSample,
            canSuggest: this.canSuggestSample,
          },
        });

        this.isLoadingFinishSchedule = false;
        this.onLoadedFinishSchedule$.next(true);
        this.projectStore.patchState({
          isChanging: false,
        });
        this.finishScheduleStore.setState({
          finishSchedule,
          isLoading: false,
        });
        this.onUpdateProject$.next();

        onEnd.next(undefined);
        onEnd.complete();
      });

    this.onChangePicklist$.subscribe((_) => {
      if (this.finishSchedule!) {
        this.finishSchedule!.picklists = this.picklists.concat([]);
        this.refleshPicklistGroups();
      }
    });

    [
      this.api.project.onBeginUpdate$,
      this.api.product.onBeginFavChange$,
    ].forEach((api) => {
      api.subscribe((_) => {
        this.needCheckUpdate = false;
      });
    });

    this.api.project.checkFromRevit().subscribe((value) => {
      this.isRevit$.next(value);
    });

    this.checkUpdate();
  }

  changeBrowseMode() {
    this.isChangedBrowseMode = this.isBrowseMode;
    //2023.06.08 材料詳細に権限状態を反映させるためストアに状態を保存
    this.editPermissionStore.updateIsBrowseMode(this.isBrowseMode);
  }

  public browseInfo: Map<RoomGroup, BrowseInfo[]>;

  public setBrowseInfo(): void {
    let browseSet: BrowseInfo[] = [];
    this.browseInfo = new Map<RoomGroup, BrowseInfo[]>();

    if (this.finishSchedule) {
      this.finishSchedule!.floor_groups!.forEach((floorGroup) => {
        floorGroup.room_groups.forEach((roomGroup) => {
          const browseSetParts: BrowseSetParts[] = [];
          let browseParts: BrowseSetParts = {};

          this.interiorCategories.forEach((category: InteriorCategory) => {
            const parts: InteriorSetParts | undefined =
              roomGroup.interiorSet.parts[category.id];

            if (browseParts.name && browseParts.name !== category.name) {
              browseSetParts.push(browseParts);
              browseParts = {};
            }

            if (!browseParts.types && parts) {
              browseParts = {
                name: category.name,
                types: [
                  {
                    id: category.id,
                    type: category.type ? category.type : '仕上',
                    parts: parts,
                  },
                ],
              };
            } else if (browseParts.types && parts) {
              browseParts.types!.push({
                id: category.id,
                type: category.type,
                parts: parts,
              });
            } else if (browseParts.types && !parts) {
              browseParts.types!.push({ id: category.id, type: category.type });
            } else if (!browseParts.types) {
              browseParts = {
                name: category.name,
                types: [
                  {
                    id: category.id,
                    type: category.type ? category.type : '仕上',
                  },
                ],
              };
            }
          });
          browseSetParts.push(browseParts);

          browseSetParts.forEach((browseSetPart) => {
            let browsePicklists: BrowsePicklist[];
            const browseInfo: BrowseInfo = {
              name: browseSetPart.name,
              types: [],
            };

            if (roomGroup.room_blocks!.length > 0) {
              for (const block of roomGroup.room_blocks!.sort((a, b) => {
                return a.order < b.order ? -1 : 1;
              })) {
                browsePicklists = [];

                browseSetPart.types!.forEach((browsePart) => {
                  const setPart: BrowseSetType | undefined =
                    browseSetPart.types!.find((v) => v.type == browsePart.type);
                  const browsePicklist: BrowsePicklist = {
                    type: browsePart.type,
                    picklists: [],
                    parts: setPart && setPart.parts ? setPart.parts : undefined,
                  };

                  const categoryId = browsePart.id!;
                  const blockParts: RoomBlockParts | undefined =
                    block.parts[categoryId];
                  if (blockParts) {
                    blockParts.picklists.forEach((blockPicklist) => {
                      const finishPicklist = setPart!.parts!.picklists.find(
                        (picklist) => picklist.id == blockPicklist.id,
                      );
                      if (finishPicklist) {
                        browsePicklist!.picklists!.push({
                          picklist: finishPicklist,
                        });
                        if (browsePicklist!.picklists!.length > 1)
                          browsePicklist!.picklists!.sort((a, b) => {
                            return a.order! < b.order! ? -1 : 1;
                          });
                      }
                    });
                  } else if (setPart && setPart.parts) {
                    const categoryPicklistsCnt = roomGroup
                      .room_blocks!.map((b) =>
                        b.parts[categoryId] && b.parts[categoryId].picklists
                          ? b.parts[categoryId].picklists.length
                          : 0,
                      )
                      .reduce((prev, curr) => prev + curr, 0);

                    if (categoryPicklistsCnt === 0) {
                      setPart.parts!.picklists.forEach((picklist) => {
                        const order = setPart!.parts!.picklistInfo.find(
                          (info) => info.id == picklist!.id,
                        )!.order;
                        browsePicklist!.picklists!.push({
                          picklist: picklist,
                          order: order,
                        });
                        if (browsePicklist!.picklists!.length > 1)
                          browsePicklist!.picklists!.sort((a, b) => {
                            return a.order! < b.order! ? -1 : 1;
                          });
                      });
                    }
                  }
                  browsePicklists.push(browsePicklist);
                });
                browseInfo.types!.push(browsePicklists);
              }
            } else {
              browsePicklists = [];

              browseSetPart.types!.forEach((browsePart) => {
                const setPart: BrowseSetType | undefined =
                  browseSetPart.types!.find((v) => v.type == browsePart.type);
                const browsePicklist: BrowsePicklist = {
                  type: browsePart.type,
                  picklists: [],
                  parts: setPart && setPart.parts ? setPart.parts : undefined,
                };

                if (setPart && setPart.parts && browsePart.type!) {
                  setPart.parts!.picklists.forEach((picklist) => {
                    const order = setPart!.parts!.picklistInfo.find(
                      (info) => info.id == picklist!.id,
                    )!.order;
                    browsePicklist!.picklists!.push({
                      picklist: picklist,
                      order: order,
                    });
                    if (browsePicklist!.picklists!.length > 1)
                      browsePicklist!.picklists!.sort((a, b) => {
                        return a.order! < b.order! ? -1 : 1;
                      });
                  });
                }
                browsePicklists.push(browsePicklist);
              });
              browseInfo.types!.push(browsePicklists);
            }

            browseSet.push(browseInfo);
          });
          this.browseInfo.set(roomGroup, browseSet);
          browseSet = [];
        });
      });
    }
  }

  getProductInfo(product?: ProductCache): DecidedProductInfo {
    if (!product) {
      return {
        name: null,
        specification: null,
        number: null,
        maker: null,
        certification: null,
        sickhouse: null,
        thickness: null,
        price: null,
        price_unit: null,
        lumberjack: null,
        thumbnail_image: null,
        texture_image: null,
        texture_width: null,
        texture_height: null,
        uniclass_code: null,
        color_image: undefined,
      };
    }
    const details = product.details;

    let priceItem: string | undefined;
    let priceUnit: string | undefined;
    [
      '^設計単価\\s*\\[円\\/㎡\\]<!--検索用-->$',
      '^設計単価\\s*\\[円\\/個\\]<!--検索用-->$',
    ].forEach((regExp) => {
      Object.keys(details)
        .filter(
          (k) =>
            k.match(new RegExp(regExp)) &&
            Number.isFinite(parseInt((details[k] || '').replace(/,/g, ''))),
        )
        .forEach((k) => {
          const match = k.match(/.*\[(.*)\]/);
          if (match && !priceItem) {
            priceItem = k;
            priceUnit = match[1];
          }
        });
    });

    const productInfo: DecidedProductInfo = {
      name: details['製品名'],
      specification: Object.keys(details)
        .filter((k) => {
          return (
            k.match(/<!--バリエーション用-->/) &&
            !!details[k] &&
            details[k] != '-'
          );
        })
        .map((k) => k.replace(/<!--.*-->/g, '') + ': ' + details[k])
        .join('\n'),
      number: details['品番<!--リスト用-->'],
      maker: details['メーカー'],
      sickhouse: details['ホルムアルデヒド放散区分<!--検索用-->'] || undefined,
      thickness: !isNaN(parseFloat(details['厚み<!--検索用-->']))
        ? parseFloat(details['厚み<!--検索用-->'].replace(/,/g, ''))
        : undefined,
      price: priceItem
        ? parseInt((details[priceItem] || '').replace(/,/g, ''))
        : undefined,
      price_unit: priceUnit || undefined,
      lumberjack: details['材工/材のみ<!--検索用-->'],
      thumbnail_image: product.display_params.image!.main,
      texture_image: product.display_params.image!.texture,
      texture_width: product.details['テクスチャ縦幅']
        ? parseInt(product.details['テクスチャ縦幅'])
        : null,
      texture_height: product.details['テクスチャ横幅']
        ? parseInt(product.details['テクスチャ横幅'])
        : null,
      uniclass_code: product.details['uniclass'],
    };

    if (product.restriction) {
      const restrictions = product.restriction!.set.filter(
        (s) => s.picklist_type == this.picklistType,
      );
      if (restrictions.length == 1) {
        productInfo.certification = restrictions[0]!.no;
      }
    }
    return productInfo;
  }

  savePicklist(
    target:
      | InteriorPicklistParams
      | ExteriorPicklistParams
      | OutdoorPicklistParams,
  ) {
    const onEnd = new Subject<
      InteriorPicklistParams | ExteriorPicklistParams | OutdoorPicklistParams
    >();
    const picklist = target.picklist;

    if (this.isParentPicklist(picklist)) {
      if (
        !this.parentPicklists.find((p) => {
          return p.id === picklist.id;
        })
      ) {
        this.parentPicklists.push(picklist);
      }
    } else {
      if (
        !this.picklists.find((p) => {
          return p.id === picklist.id;
        })
      ) {
        this.picklists.push(picklist);
      }
    }

    this.api.project
      .savePicklist(this.projectId, target)
      .subscribe((result) => {
        picklist.id = result.picklist.id;

        if (
          picklist.updated_request_memo_at !=
          result.picklist.updated_request_memo_at
        ) {
          picklist.updated_request_memo_at =
            result.picklist.updated_request_memo_at;
        }

        if (!picklist.group_id && !!result.picklist.group_id) {
          this.createParentPicklist(result.picklist);
          picklist.group_id = result.picklist.group_id;
        }
        const listProducts = result.picklist.listProducts;

        if (listProducts && listProducts.length > 0) {
          this.candidateProductFacade.createMany(result.picklist.listProducts);
        }
        this.updateDisplayListLink(picklist);
        onEnd.next(result);
        onEnd.complete();
      });

    this.onChangePicklist$.next();

    return onEnd.asObservable();
  }

  saveListLink(picklist: Picklist, isRemove = false) {
    if (!this.currentPicklist) return;

    if (!isRemove) {
      return this.api.project
        .addListLink(this.projectId, this.currentPicklist, picklist)
        .pipe(
          tap((result) => {
            this.candidateProductFacade.createMany(result.to.listProducts);
            this.currentPicklist!.list_link_id = result.from.list_link_id;
            this.updateDisplayListLink(result.from);
          }),
        );
    } else {
      picklist.list_link_id = undefined;
      return this.api.project
        .removeListLink(this.projectId, this.currentPicklist)
        .pipe(
          tap((result) => {
            this.picklists.reduce((list, p) => {
              if (p.id == result.from.id) {
                this.currentPicklist!.list_link_id = result.from.list_link_id;
              } else if (p.id == result.to.id) {
                this.picklists.find((picklist) => {
                  return picklist.id == result.to.id;
                })!.list_link_id = result.to.list_link_id;
              }
              return list;
            }, {});
          }),
        );
    }
  }

  removeListLink(picklist: Picklist): Observable<{
    from: Picklist;
    to: Picklist;
  }> {
    return this.api.project.removeListLink(this.projectId, picklist);
  }

  getOtherLinkPicklist(picklist: Picklist): Picklist | undefined {
    let to = this.picklists.find((p) => {
      return (
        p.id !== picklist.id &&
        p.list_link_id &&
        picklist.list_link_id &&
        p.list_link_id == picklist.list_link_id
      );
    });

    if (!to) {
      to = this.picklists.find((p) => {
        return p.id == picklist.list_link_id;
      });
    }

    return to;
  }

  updateDisplayListLink(picklist: Picklist, isRemoved = false): void {
    if (picklist.list_link_id) {
      const otherLinkedPicklist = this.getOtherLinkPicklist(picklist);
      if (isRemoved) {
        picklist!.list_link_id = null;

        if (otherLinkedPicklist) {
          otherLinkedPicklist.list_link_id = null;
        }
      } else {
        const reject: (keyof Picklist)[] = [
          'id',
          'revit_id',
          'group_id',
          'project_id',
          'type',
        ];
        const tmp: {
          [key: string]: any;
        } = {};

        if (otherLinkedPicklist) {
          for (const r of reject) {
            tmp[r] = otherLinkedPicklist[r];
          }
          Object.assign(otherLinkedPicklist, picklist, tmp);
        }
      }
    }
  }

  hasApprovedRoom(picklistId: number): boolean {
    const targetPicklist = this.allPicklists?.find((p) => p.id == picklistId);
    if (!targetPicklist) {
      return false;
    }

    switch (targetPicklist.type) {
      case PicklistType.Interior: {
        const setIds = this.interiroSets.reduce((list, s) => {
          const usingCategories = this.interiorCategories.filter((c) => {
            if (!s.parts[c.id]) return false;
            return (
              s.parts[c.id].picklists.filter((p) => p.id == picklistId).length >
              0
            );
          });
          if (usingCategories.length > 0) list.push(s.id);
          return list;
        }, [] as number[]);

        let hasApprovedRoom = false;

        const floorGroups$ =
          this.getFloorGroupViewModelQuery.getViewModelList();
        floorGroups$.subscribe((floorGroups: FloorGroupViewModel[]) => {
          for (const floorGroup of floorGroups) {
            const targetRoomGroups = floorGroup.room_groups.filter(
              (r) => setIds.indexOf(r.interior_set_id) > -1,
            );

            for (const roomGroup of targetRoomGroups) {
              if (roomGroup.is_approved) hasApprovedRoom = true;
            }
          }
        });

        return hasApprovedRoom;
      }

      case PicklistType.Exterior: {
        const approvedSets = this.exteriorCategories.reduce((list, e) => {
          list = list.concat(
            e.sets.filter((s) => {
              return (
                s.is_approved &&
                s.picklists.filter((p) => p.id == picklistId).length > 0
              );
            }),
          );
          return list;
        }, [] as ExteriorSet[]);
        return approvedSets.length > 0;
      }

      default:
        return false;
    }
  }

  isOtherLinkPicklistHasApprovedRoom(picklistId: number) {
    const picklist = this.picklists.find((p) => p.id == picklistId);
    if (!picklist) return false;
    const otherLinkPicklist = this.getOtherLinkPicklist(picklist);
    if (!otherLinkPicklist) return false;
    return this.hasApprovedRoom(otherLinkPicklist.id);
  }

  getSentenceOnApproved(
    picklist: Picklist | undefined,
    allowedSentence = '編集',
  ): string | null {
    if (picklist) {
      const otherLinkPicklist = this.getOtherLinkPicklist(picklist);
      const hasApprovedRoom = this.hasApprovedRoom(picklist.id);
      const isOtherLinkPicklistHasApprovedRoom =
        this.isOtherLinkPicklistHasApprovedRoom(picklist.id);

      const template = `承認済みの部屋で使用されています。\n本当に採用製品を${allowedSentence}しますか？`;

      if (hasApprovedRoom && !isOtherLinkPicklistHasApprovedRoom) {
        return 'この材料は、' + template;
      } else if (
        !hasApprovedRoom &&
        isOtherLinkPicklistHasApprovedRoom &&
        otherLinkPicklist
      ) {
        return (
          `リンクしている${getPicklistTypeLabel(
            otherLinkPicklist.type,
          )}領域の材料「${otherLinkPicklist.name}」が、` + template
        );
      } else if (
        hasApprovedRoom &&
        isOtherLinkPicklistHasApprovedRoom &&
        otherLinkPicklist
      ) {
        return (
          `この材料と、リンクしている${getPicklistTypeLabel(
            otherLinkPicklist.type,
          )}領域の材料「${otherLinkPicklist.name}」の両方が、\n` + template
        );
      }
    }
    return null;
  }

  changeListProductStatus(projectId: number, from: string, to: string) {
    this.api.project.changeListProductStatus(projectId, from, to).subscribe();
  }

  onLoadRequestedProductCount$ = new Subject<void>();

  onSearchSimilarProduct(
    searchSimilarProductDialog: SearchSimilarProductDialogService,
    productCache: ProductCache,
    picklist?: Picklist,
  ) {
    if (picklist) {
      this.currentPicklist = picklist;
    }

    let similarCategories!: string[];
    let similarCategoriesCount!: number;

    const categoryIds: string[] = productCache.category_ids;
    const productId: string = productCache.product_id;

    if (categoryIds.length > 1) {
      if (this.currentCategories && this.currentCategories.length > 0) {
        similarCategories = categoryIds.filter((element) => {
          return !!this.currentCategories.find((item) => item.id === element);
        });
        similarCategoriesCount = similarCategories.length;
      }
    } else {
      similarCategories = categoryIds;
    }

    if (similarCategoriesCount > 1) {
      searchSimilarProductDialog
        .openDialog(categoryIds)
        .afterClosed()
        .subscribe((r) => {
          if (r) {
            if (picklist) {
              // this.user.updateActiveList(picklist?.id);
            }
            this.jumpToSearchSimilarProduct(r, productId);
          }
        });
    } else {
      if (picklist) {
        // this.user.updateActiveList(picklist?.id);
      }
      this.jumpToSearchSimilarProduct(similarCategories[0], productId);
    }
  }

  jumpToSearchSimilarProduct(
    categoryId: string | undefined = undefined,
    productId: string,
  ) {
    location.href = `similar-products/project/${this.currentProjectId}/${this.picklistType}/picklist/${this.currentPicklistId}/products/${categoryId}/${productId}`;
  }

  _usedInteriorPicklists: { name: string; picklists: Picklist[] }[] = [];

  get usedInteriorPicklists(): { name: string; picklists: Picklist[] }[] {
    return this._usedInteriorPicklists;
  }

  set usedInteriorPicklist(value: { name: string; picklists: Picklist[] }) {
    this._usedInteriorPicklists.push(value);
  }

  _usedExteriorPicklists: { name: string; picklists: Picklist[] }[] = [];

  get usedExteriorPicklists(): { name: string; picklists: Picklist[] }[] {
    return this._usedExteriorPicklists;
  }

  get interiorStandardSets() {
    if (!this.finishSchedule || !this.finishSchedule!.standardSets) return [];
    return this.finishSchedule?.standardSets?.interior || [];
  }

  get exteriorStandardSets() {
    if (!this.finishSchedule || !this.finishSchedule!.standardSets) return [];
    return this.finishSchedule?.standardSets?.exterior || [];
  }

  get interiorParentPicklists() {
    if (!this.finishSchedule || !this.finishSchedule!.parentPicklists)
      return [];
    return (
      this.finishSchedule?.parentPicklists?.filter((p) => {
        return p.type == PicklistType.Interior;
      }) || []
    );
  }
  get exteriorParentPicklists() {
    if (!this.finishSchedule || !this.finishSchedule!.parentPicklists)
      return [];
    return (
      this.finishSchedule?.parentPicklists?.filter((p) => {
        return p.type == PicklistType.Exterior;
      }) || []
    );
  }

  //query
  get interiorParentPicklistMap() {
    return [...this.interiorStandardPicklistMap.keys()].reduce(
      (collection, name) => {
        const picklists =
          this.interiorStandardPicklistMap
            .get(name)
            ?.reduce((collection, picklistId) => {
              const picklist = this.picklists.find((picklist) => {
                return picklist.group_id == picklistId;
              });

              if (picklist) {
                collection.push(picklist);
              }
              return collection;
            }, [] as Picklist[]) || [];

        if (picklists && picklists.length > 0) {
          collection.set(name, picklists);
        }

        return collection;
      },
      new Map<string, Picklist[]>(),
    );
  }

  get exteriorParentPicklistMap() {
    return [...this.exteriorStandardPicklistMap.keys()].reduce(
      (collection, setName) => {
        const picklists =
          this.exteriorStandardPicklistMap
            .get(setName)
            ?.map((picklistId) => {
              return this.picklists.find((picklist) => {
                return picklist.group_id == picklistId;
              })!;
            })
            .filter((picklist) => {
              return !!picklist;
            }) || [];

        if (picklists && picklists.length > 0) {
          collection.set(setName, picklists);
        }

        return collection;
      },
      new Map<string, Picklist[]>(),
    );
  }

  refleshPicklistGroups() {
    this.refleshInteriorPicklistGroups();
    this.refleshExteriorPicklistGroups();
    this.refleshExteriorBySetsPicklistGroups();
  }

  _interiorPicklistGroups:
    | { name: string; picklists: Picklist[] }[]
    | undefined;
  get interiorPicklistGroups() {
    if (!this._interiorPicklistGroups) this.refleshInteriorPicklistGroups();
    return this._interiorPicklistGroups;
  }
  refleshInteriorPicklistGroups() {
    this._usedInteriorPicklists = [];
    const groups = this.interiorCategories.map((c) => {
      const name: string = c.name + (c.type || '');

      const usedInteriorPicklists = this.interiroSets
        .reduce((groups, set) => {
          return [
            ...groups,
            ...(set.parts[c.id] || { picklists: [] as Picklist[] }).picklists,
          ];
        }, [] as Picklist[])
        .filter((x, i, self) => self.indexOf(x) === i);

      this.usedInteriorPicklist = {
        name: name,
        picklists: usedInteriorPicklists,
      };

      const standardPicklists =
        this.interiorParentPicklistMap.get(name)?.filter((p) => {
          return usedInteriorPicklists.indexOf(p) < 0;
        }) || [];

      this.disableScrollTrigger$;

      return {
        name: name,
        picklists: [...usedInteriorPicklists, ...standardPicklists],
      };
    });

    this._interiorPicklistGroups = [
      {
        name: '未使用',
        picklists: this.picklists.filter((picklist) => {
          return (
            picklist.type === PicklistType.Interior &&
            groups
              .reduce(
                (list, group) => [...list, ...group.picklists],
                [] as Picklist[],
              )
              .indexOf(picklist) < 0 &&
            !this.parentPicklists.find((p) => {
              return (
                p.type == PicklistType.Interior && p.id == picklist.group_id
              );
            })?.is_standard
          );
        }),
      },
      ...groups,
    ].filter((g) => g.picklists.length > 0);
  }

  _exteriorPicklistGroups: { name: string; picklists: Picklist[] }[];
  get exteriorPicklistGroups() {
    if (!this._exteriorPicklistGroups) this.refleshExteriorPicklistGroups();
    return this._exteriorPicklistGroups;
  }
  refleshExteriorPicklistGroups() {
    const groups = this.exteriorCategories.map((c) => {
      return {
        name: c.name,
        picklists: c.sets
          .reduce((groups, set) => {
            return [...groups, ...set.picklists];
          }, [] as Picklist[])
          .filter((x, i, self) => self.indexOf(x) === i),
      };
    });

    this._exteriorPicklistGroups = [
      {
        name: '未使用',
        picklists: this.picklists.filter((picklist) => {
          return (
            picklist.type === PicklistType.Exterior &&
            groups
              .reduce(
                (list, group) => [...list, ...group.picklists],
                [] as Picklist[],
              )
              .indexOf(picklist) < 0
          );
        }),
      },
      ...groups,
    ].filter((g) => g.picklists.length > 0);
  }

  _exteriorBySetPicklistGroups: { name: string; picklists: Picklist[] }[];
  get exteriorBySetPicklistGroups() {
    if (!this._exteriorBySetPicklistGroups)
      this.refleshExteriorBySetsPicklistGroups();
    return this._exteriorBySetPicklistGroups;
  }

  public refleshExteriorBySetsPicklistGroups() {
    this._usedExteriorPicklists = [];

    const groups = [...this.exteriorParentPicklistMap.keys()]
      .filter((exteriorSetName) => {
        return exteriorSetName !== '*';
      })
      .reduce(
        (groups, exteriorSetName) => {
          const picklists = this.exteriorParentPicklistMap.get(exteriorSetName);
          const group = groups.find((g) => g.name == exteriorSetName);

          if (group && picklists) {
            const _picklists = picklists.filter((p) => {
              return group.picklists.indexOf(p) < 0;
            });
            group.picklists = group.picklists.concat(_picklists);
          } else if (picklists) {
            groups.push({
              name: exteriorSetName,
              picklists: picklists,
            });
          }

          return groups;
        },
        [] as { name: string; picklists: Picklist[] }[],
      );

    this.exteriorCategories
      .sort((a, b) =>
        a.finish_schedule_order < b.finish_schedule_order ? -1 : 1,
      )
      .forEach((category) => {
        category.sets
          .sort((a, b) =>
            a.finish_schedule_order < b.finish_schedule_order ? -1 : 1,
          )
          .map((s) => {
            return {
              name: s.name,
              picklists: s.picklists,
            };
          })
          .filter((s) => {
            return s.picklists.length > 0;
          })
          .forEach((s) => {
            [groups, this._usedExteriorPicklists].forEach((_groups) => {
              const group = _groups.find((g) => g.name == s.name);
              if (group) {
                group.picklists = group.picklists.concat(s.picklists);
              } else {
                const index = _groups.filter((_group) => {
                  return ![...this.exteriorStandardPicklistMap.keys()].find(
                    (map) => {
                      return map == _group.name;
                    },
                  );
                }).length;

                _groups.splice(index, 0, s);
              }
            });
          });
      });

    this._exteriorBySetPicklistGroups = [
      {
        name: '未使用',
        picklists: (() => {
          const unusedPicklists = this.picklists.filter((picklist) => {
            return (
              picklist.type === PicklistType.Exterior &&
              groups
                .reduce(
                  (list, group) => [...list, ...group.picklists],
                  [] as Picklist[],
                )
                .indexOf(picklist) < 0 &&
              !this.parentPicklists.find((p) => {
                return (
                  p.type == PicklistType.Exterior && p.id == picklist.group_id
                );
              })?.is_standard
            );
          });

          let standardPicklists: Picklist[] = [];
          const map = this.exteriorParentPicklistMap.get('*');
          if (map) {
            standardPicklists =
              map?.filter((picklist) => {
                return (
                  picklist.type === PicklistType.Exterior &&
                  groups
                    .reduce(
                      (list, group) => [...list, ...group.picklists],
                      [] as Picklist[],
                    )
                    .indexOf(picklist) < 0
                );
              }) || [];
          }

          return [...standardPicklists, ...unusedPicklists];
        })(),
      },
      ...groups,
    ].filter((g) => g.picklists.length > 0);
  }

  setCurrentPicklist() {
    if (this.finishSchedule && this.currentPicklistId) {
      this.currentPicklist = this.allPicklists.find((picklist) => {
        return picklist.id == this.currentPicklistId;
      });
    }
  }

  public getParentPicklist(
    groupId: number,
    type: PicklistType = this.picklistType,
  ): Picklist | undefined {
    return (() => {
      switch (type) {
        case PicklistType.Interior:
          return this.interiorParentPicklists;

        case PicklistType.Exterior:
          return this.exteriorParentPicklists;

        default:
          return [];
      }
    })().find((p) => p.id == groupId);
  }

  public getReletaionPicklists(picklist: Picklist): Picklist[] {
    let relations: Picklist[] = [];

    if (this.isParentPicklist(picklist)) {
      relations = this.picklists.filter((p) => {
        return picklist.id == p.group_id;
      });
    } else if (!this.isParentPicklist(picklist)) {
      relations = this.picklists.filter((p) => {
        return picklist.group_id == p.group_id && picklist.id !== p.id;
      });
    }

    return relations && relations.length > 0 ? relations : [];
  }

  //picklist.serviceに移行予定
  public isParentPicklist(picklist: Picklist) {
    return (
      (picklist.type == PicklistType.Interior ||
        picklist.type == PicklistType.Exterior) &&
      picklist.is_parent
    );
  }

  // TODO: picklist.serviceに移行予定
  public isStandardPicklist(picklist?: Picklist) {
    return picklist
      ? (picklist.type == PicklistType.Interior ||
          picklist.type == PicklistType.Exterior) &&
          !!picklist.is_standard
      : false;
  }

  get interiorSetsByBuildingUse() {
    const projectBuildingUse =
      this.finishSchedule?.info_building_use.concat() || [];

    projectBuildingUse.push('共通');

    return this.interiorStandardSets.filter((stardardSet) => {
      const standardSetBuildingUse = stardardSet.building_use.split(',');
      return (
        standardSetBuildingUse
          .concat(projectBuildingUse)
          .filter((buildingUse, i, self) => {
            return self.indexOf(buildingUse) !== i;
          }).length > 0
      );
    });
  }

  public createParentPicklist(childPicklist: Picklist) {
    const copiedFields: (keyof Picklist)[] = [
      'project_id',
      'type',
      'name',
      'specification',
      'assign_type',
      'enable_similar',
      'remarks',
      'memo',
      'enable_multiple_paste',
      'created_at',
      'updated_at',
    ];
    const setFalseFileds: (keyof Picklist)[] = ['is_standard'];

    const setTrueFields: (keyof Picklist)[] = ['is_parent'];

    const setValues: {
      [key: string]: any;
    } = {};

    Object.keys(childPicklist).forEach((key: keyof Picklist) => {
      if (copiedFields.includes(key)) {
        setValues[key] = childPicklist[key];
      } else if (setFalseFileds.includes(key)) {
        setValues[key] = false;
      } else if (setTrueFields.includes(key)) {
        setValues[key] = true;
      } else {
        setValues[key] = null;
      }
    });

    const parentPicklist = Object.assign({}, childPicklist, setValues);
    parentPicklist.id = childPicklist.group_id!;
    parentPicklist.listProductCount = 0;

    this.parentPicklists.push(parentPicklist);
  }

  _interiorStandardPicklistMap: Map<string, number[]> = new Map<
    string,
    number[]
  >();

  //query
  get interiorStandardPicklistMap(): Map<string, number[]> {
    if (this._interiorStandardPicklistMap.size == 0) {
      const _interiorStandardSets = this.interiorStandardSets.reduce(
        (collection, standardSet) => {
          return [...collection, ...standardSet.room_group_info];
        },
        [] as { parts_name: string; picklists: number[] }[],
      );

      this._interiorStandardPicklistMap = this.interiorCategories.reduce(
        (collection, categoryName) => {
          const name = categoryName.name + (categoryName.type || '');

          const picklistIds = _interiorStandardSets
            .filter((standardSet) => {
              return standardSet.parts_name == name;
            })
            .reduce((collection, standardSet) => {
              return Array.from(
                new Set([...collection, ...standardSet.picklists]),
              );
            }, [] as number[]);

          if (picklistIds && picklistIds.length > 0) {
            collection.set(name, picklistIds);
          }

          return collection;
        },
        new Map<string, number[]>(),
      );
    }

    return this._interiorStandardPicklistMap;
  }

  _exteriorStandardPicklistMap: Map<string, number[]> = new Map<
    string,
    number[]
  >();
  get exteriorStandardPicklistMap(): Map<string, number[]> {
    if (this._exteriorStandardPicklistMap.size == 0) {
      this._exteriorStandardPicklistMap = this.exteriorStandardSets
        .reduce(
          (collection, standardSet) => {
            return [
              ...collection,
              ...standardSet.exterior_category_info.map((info) => {
                return {
                  exterior_set_name: info.exterior_set_name,
                  picklists: info.picklists,
                };
              }),
            ];
          },
          [] as { exterior_set_name: string; picklists: number[] }[],
        )
        .reduce((collection, standardSet) => {
          const picklistIds = Array.from(new Set([...standardSet.picklists]));

          const map = collection.get(standardSet.exterior_set_name);

          if (map) {
            collection.set(standardSet.exterior_set_name, [
              ...map,
              ...picklistIds,
            ]);
          } else {
            collection.set(standardSet.exterior_set_name, picklistIds);
          }

          return collection;
        }, new Map<string, number[]>());
    }
    return this._exteriorStandardPicklistMap;
  }

  setProjectId(projectId: number): Subject<undefined> {
    const onEnd = new Subject<undefined>();
    this.currentProjectId = projectId;
    this.onChangeProjectId$.next({ projectId, onEnd });

    return onEnd;
  }

  checkUpdate() {
    setTimeout(() => {
      this._checkUpdate();
    }, 5000);
  }
  _checkUpdate() {
    if (this.finishSchedule) {
      this.api.project.monitorChanges(this.finishSchedule.id).subscribe((p) => {
        if (this.finishSchedule) {
          this.finishSchedule!.latestListLog = p.latestListLog || undefined;
        }

        if (p.updated_at != this.finishSchedule?.updated_at) {
          if (this.currentProject) {
            this.projectStore.updateCurrentProject(this.currentProject);
          }

          this.updateProject = undefined;
          if (this.needCheckUpdate) {
            this.onGenerateMessage$.next({
              message: '変更を同期しています...',
            });
            this.reloadFinishSchedule().subscribe((_) => {
              if (this.favToggleEvent) {
                const onEnd = new Subject<undefined>();
                this.favToggleEvent.next({ onEnd });
                onEnd.next(undefined);
              }
              this.onGenerateMessage$.next({
                message: '変更を同期しました。',
                keep: 3000,
              });
              this.checkUpdate();
            });
          } else {
            (this.finishSchedule || { updated_at: '' }).updated_at =
              p.updated_at;
            this.needCheckUpdate = true;
            this.checkUpdate();
          }
        } else {
          this.checkUpdate();
        }
      });
    } else {
      this.checkUpdate();
    }
  }

  reloadFinishSchedule() {
    const onEnd = new Subject<undefined>();
    if (this.finishSchedule)
      this.onChangeProjectId$.next({
        projectId: this.finishSchedule.id,
        onEnd,
        isReload: true,
      });

    return onEnd;
  }

  async saveParts(
    interiorSet: InteriorSet,
    parts: InteriorSetParts,
  ): Promise<{
    interiorSet: InteriorSet;
    parts: InteriorSetParts;
  }> {
    const result = await firstValueFrom(
      this.api.project.saveParts(this.projectId, { interiorSet, parts }),
    );
    // ↓参照渡しのようなものを利用(https://qiita.com/yuta0801/items/f8690a6e129c594de5fb#%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AF%E5%8F%82%E7%85%A7%E6%B8%A1%E3%81%97%E3%81%A8%E8%A8%80%E3%82%8F%E3%82%8C%E3%81%A6%E3%81%8D%E3%81%9F%E3%82%82%E3%81%AE)
    interiorSet.parts[parts.interior_category_id] = parts;
    parts.id = result.parts.id;
    parts.interior_set_id = result.parts.interior_set_id;
    return result;
  }

  duplicateExteriorCategory(
    exteriorCategory: ExteriorCategory,
  ): Observable<ExteriorCategory> {
    return this.exteriorCategoryApiService.duplicate(exteriorCategory).pipe(
      map((result) => {
        // メンバー変数のfinishScheduleに変更を反映させる処理

        // exteriorSets.picklistInfoに対応したpicklistをメンバー変数にあるpicklistsから探してexteriorSetsに入れる
        result.exteriorSets = result.exteriorSets.map((exteriorSet) => {
          const picklistIds = exteriorSet.picklistInfo
            .sort((a, b) => a.order - b.order)
            .map((info) => info.id);
          exteriorSet.picklists = picklistIds
            .map((id) => this.picklists.find((picklist) => picklist.id === id))
            .flatMap((picklist) => picklist ?? []);
          // ↑tsのコンパイルエラーを防止する処理（undefinedを弾いていることを明示）
          return exteriorSet;
        });

        // exteriorCategoryにpicklistsを入れた後のexteriorSetsを入れる
        result.exteriorCategory.sets = result.exteriorSets;

        if (this.finishSchedule) {
          // 分類の並びを入れ替え（複製された分類の下に置く分類のorderを１つずつずらす）
          const newOrder = result.exteriorCategory.finish_schedule_order;
          this.finishSchedule.exterior_categories =
            this.finishSchedule.exterior_categories?.map(
              (_exteriorCategory) => {
                if (_exteriorCategory.finish_schedule_order >= newOrder)
                  _exteriorCategory.finish_schedule_order += 1;
                return _exteriorCategory;
              },
            );
          // finishScheduleに追加した分類を入れる
          this.finishSchedule.exterior_categories?.push(
            result.exteriorCategory,
          );
        }
        return result.exteriorCategory;
      }),
    );
  }
}
