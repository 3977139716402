import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExteriorCategory } from 'app/v2/general/features/project/_type/exterior-category';
import { ExteriorSet } from 'app/v2/general/features/project/_type/exterior-category-set';
import { Observable } from 'rxjs';

import { ProjectService } from './project.service';

export interface DuplicateExteriorCategoryResult {
  exteriorCategory: ExteriorCategory;
  exteriorSets: ExteriorSet[];
}

@Injectable({
  providedIn: 'root',
})
export class ExteriorCategoryApiService {
  constructor(
    private http: HttpClient,
    private projectApiService: ProjectService,
  ) {}

  duplicate(
    exteriorCategory: ExteriorCategory,
  ): Observable<DuplicateExteriorCategoryResult> {
    this.projectApiService.onBeginUpdate$.next();
    return this.http.post<DuplicateExteriorCategoryResult>(
      `/api/project/${exteriorCategory.project_id}/finish-schedule/exterior-category/${exteriorCategory.id}/duplicate`,
      null,
    );
  }
}
