import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { initPicklist } from '@shared/models/response/picklist';
import { Observable } from 'rxjs';

import { PicklistType } from '../../domain/enums/picklist-type.enum';
import { Picklist } from '../../domain/types/states/picklist.state';

type MiscellaneousWorkPicklistsState = {
  miscellaneousWorkPicklists: MiscellaneousWorkPicklist[];
};

export type MiscellaneousWorkPicklist = Picklist & {
  specCode: string;
  standardDimension: string;
};

@Injectable({ providedIn: 'root' })
export class MiscellaneousWorkPicklistStore extends ComponentStore<MiscellaneousWorkPicklistsState> {
  constructor() {
    super({ miscellaneousWorkPicklists: [] });
  }

  readonly miscellaneousWorkPicklists$: Observable<
    MiscellaneousWorkPicklist[]
  > = this.select((state) => {
    return state.miscellaneousWorkPicklists;
  });

  readonly addPicklist = this.updater(
    (
      state: MiscellaneousWorkPicklistsState,
      newPicklist: MiscellaneousWorkPicklist,
    ) => {
      return {
        miscellaneousWorkPicklists: [
          ...state.miscellaneousWorkPicklists,
          newPicklist,
        ],
      };
    },
  );

  readonly updatePicklist = this.updater(
    (state, newPicklist: MiscellaneousWorkPicklist) => {
      const updatedItems = state.miscellaneousWorkPicklists.map((item) => {
        if (item.id === newPicklist.id) {
          return newPicklist;
        }
        return item;
      });

      return { ...state, miscellaneousWorkPicklists: updatedItems };
    },
  );

  readonly deletePicklist = this.updater((state, deletePicklistId: number) => {
    const newState = state.miscellaneousWorkPicklists.filter(
      (picklist) =>
        deletePicklistId !== picklist.id &&
        deletePicklistId !== picklist.group_id!,
    );

    return { miscellaneousWorkPicklists: newState } as const;
  });
}

export const InitialMiscellaneousWorkPicklistStateType: MiscellaneousWorkPicklist =
  {
    ...initPicklist(PicklistType.MiscellaneousWork),
    specCode: '',
    standardDimension: '',
  };
