import { JoineryPicklistSpecification } from '@interfaces/joinery-picklist-specification';
import { ExteriorRequirement } from 'app/v2/general/features/project/exterior/_type/exterior-requirement';
import { ExteriorType } from 'app/v2/general/features/project/exterior/_type/exterior-type.enum';

import { List } from '../list';

import { ListProduct } from './list-product';
import { HasDate } from './sub/has-date';
import { ImagePreviewType } from './sub/image-preview-type';
import { PicklistType } from './sub/picklist-type';
import { ThumbnailType } from './sub/thumbnail-type';

export interface Picklist extends HasDate {
  id: number;
  revit_id: string | null;
  project_id: number;
  type: PicklistType;
  is_standard?: boolean;
  is_parent?: boolean;
  name: string;
  group_id?: number | null;
  specification: string | null;
  assign_type: string;
  enable_similar: number;
  similar_memo: string;
  enable_multiple_paste?: boolean;
  thickness?: number | null;
  remarks: string | null;
  request_memo?: string;
  updated_request_memo_at?: Date | null;
  updated_request_memo_user_name?: string | null;
  updated_request_memo_user_id?: number | null;
  memo: string | null;
  //truss-684
  // exteriorOrder?: number;
  product_image_preview_type?: ImagePreviewType;
  product_use_image?: ThumbnailType;
  order?: List<number> | null;
  mark?: string;
  keynote?: string;
  surface_pattern_id?: number | null;
  list_link_id?: number | null;
  bim_color?: string;
  is_multiple_paste?: boolean;
  // 2023/8/2 左リストに構造追加
  is_structural_material: boolean;
  exterior_type?: ExteriorType | null;
  structural_code: string | null;
  exterior_requirements: ExteriorRequirement[];
  certification: string;
  listProducts: ListProduct[];
  meta?: PicklistMeta;
  joinery_standard_component_id?: number;
  joinery_picklist_specifications?: JoineryPicklistSpecification[];
  joinery_component_ids?: number[];
}

export interface PicklistMeta {
  multiple?: {
    count: number;
    source_picklist_id: number;
    source_picklist_name: string;
  };
}

export const initPicklist = (type: PicklistType): Picklist => {
  return {
    id: 0,
    revit_id: null,
    project_id: 0,
    type,
    is_standard: false,
    is_parent: false,
    name: '',
    group_id: null,
    specification: '',
    assign_type: 'input popular name',
    enable_similar: 0,
    similar_memo: '',
    enable_multiple_paste: false,
    thickness: null,
    remarks: '',
    request_memo: '',
    updated_request_memo_at: null,
    updated_request_memo_user_name: null,
    updated_request_memo_user_id: null,
    memo: '',
    product_image_preview_type: ImagePreviewType.Cover,
    product_use_image: ThumbnailType.Product,
    order: null,
    mark: '',
    keynote: '',
    surface_pattern_id: null,
    list_link_id: null,
    bim_color: '',
    is_multiple_paste: false,
    is_structural_material: false,
    structural_code: null,
    exterior_requirements: [],
    certification: '',
    listProducts: [],
    meta: undefined,
    joinery_standard_component_id: 0,
    joinery_picklist_specifications: [],
    joinery_component_ids: [],
    created_at: '',
    updated_at: '',
  };
};
